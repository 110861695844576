// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import GoogleTagManager from '../GoogleTagManager/GoogleTagManager';
import ShownPixel from '../ShownPixel/ShownPixel';

function TopBar(props) {
    const has_mobile = props.clinic.contact.mobile.length > 0;
    let contact_no = "--";
    if(has_mobile) {
        contact_no = props.clinic.contact.mobile[0];
    }

    let email = "--";
    const has_email = props.clinic.contact.email.length > 0
    if(has_email) {
        email = props.clinic.contact.email;
    }
    const has_facebook = "facebook" in props.clinic.social_media;
    const has_instagram = "instagram" in props.clinic.social_media;
    const has_youtube = "youtube" in props.clinic.social_media;
    return (
    <div className="top-bar">
        <Container>
            <Row>
                <Col md={2} sm={12}>
                    <div className="call-info">
                        <p className="call-text"><i className="fa fa-phone"></i><strong>{ contact_no }</strong></p>
                    </div>
                </Col>
                <Col md={3} className="d-none d-md-block">
                    <div className="call-info">
                        <p className="call-text"><i className="fa fa-envelope-open-o"></i><strong>{ email }</strong></p>
                    </div>
                </Col>
                <Col md={7} className="d-none d-md-block">
                    <div className="social">
                        <ul>
                            {has_facebook ? <li><a href={props.clinic.social_media.facebook} target={"_blank"} rel="noreferrer"><i className="fa fa-facebook"></i></a></li> : null}
                            {has_instagram ? <li><a href={props.clinic.social_media.instagram} target={"_blank"} rel="noreferrer"><i className="fa fa-instagram"></i></a></li> : null}
                            {has_youtube ? <li><a href={props.clinic.social_media.youtube} target={"_blank"} rel="noreferrer"><i className="fa fa-youtube"></i></a></li> : null}
                        </ul>
                    </div>
                </Col>
            </Row>
        </Container>
        {props.clinic.google_tag !== undefined?
            <GoogleTagManager trackingId={props.clinic.google_tag.trackingId}/>:""}
    </div>
    );
  }
  
  export default TopBar;