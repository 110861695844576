import React, { Component } from 'react'
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from './theme/global-style';
import * as clinicData from './testdata/clinic_data.json';

// Pages
import Home from './pages/Home';
import Appointment from './pages/Appointment'
import About from './pages/About';
import Gallery from './pages/Gallery';
import TreatmentPage from './pages/TreatmentPage';
import CustomPage from './pages/Custom';
import Error404Page from './pages/error404'

// Helpers
import Loader from "./components/Helper/Loader";
import {Mode, Capability} from './components/Helper/constants';
import {get_request_option} from "./components/Helper/helper";

import ScrollToTop from './components/ScrollToTop';
import ABDMEnrollment from './pages/ABDMEnrollment';

export default class App extends Component {

  componentDidMount() {
    if(Mode.DEBUG) {
      var clinic = clinicData.default;
      this.setState({ clinic : clinic});
    } else {
      let capJson = JSON.stringify( Capability );
      fetch(this.state.clinicDataUrl, get_request_option("POST", capJson))
          .then(response => response.json())
          .then(data => {
              this.setState({ clinic : data});
          })
          .catch( err => console.log(err));
    }
    document.wow.init();
  }

  state = {
    clinicDataUrl : '/api/clinic'
  }

  render() {
    if(!this.state.clinic) {
      return <Loader />
    }
    return (
      <ThemeProvider theme={ this.state.clinic.theme }>
        <Loader timeout='300'/>
        <GlobalStyles/>
        <ScrollToTop />
        <Routes>
          <Route exact path="appointment" element={<Appointment clinic={this.state.clinic} />} />
          <Route exact path="appointment/submitted" element={<Appointment clinic={this.state.clinic} />} />
          <Route exact path="abdm/enroll" element={<ABDMEnrollment clinic={this.state.clinic} />} />
          <Route exact path="about" element={<About clinic={this.state.clinic} />} />
          <Route exact path="gallery" element={<Gallery clinic={this.state.clinic} />} />
          <Route index element={<Home clinic={this.state.clinic} />} />
          <Route exact path="treatments/:treatmentId" element={<TreatmentPage clinic={this.state.clinic} />} />
          <Route exact path="page/:pageTag" element={<CustomPage clinic={this.state.clinic}/>} />
          <Route path='*' element={<Error404Page clinic={this.state.clinic}/>}/>
        </Routes>
      </ThemeProvider>
    );
  }
}
