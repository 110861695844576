import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// helper
import {get_request_option} from '../Helper/helper'
import {Mode} from '../Helper/constants';
import * as abdm_request_otp_success from '../../testdata/abdm_request_otp_success.json';
import * as abdm_verify_success_new_card_differnt_mobile from '../../testdata/abdm_verify_otp_success_new_card_different_mobile.json'
// required for testing
//import * as abdm_request_otp_fail_invalid_aadhar from '../../testdata/abdm_request_otp_fail_invalid_aadhar.json';
//import * as abdm_request_otp_fail_other_error from '../../testdata/abdm_request_otp_fail_other_error.json';
import * as abdm_verify_otp_success_new_card_same_mobile from '../../testdata/abdm_verify_otp_success_new_card_same_mobile.json'
import * as abdm_verify_otp_success_exiting_card_same_mobile from '../../testdata/abdm_verify_otp_success_exiting_card_same_mobile.json'
import * as abdm_verify_otp_fail_invalid_mobile from '../../testdata/abdm_verify_otp_fail_invalid_mobile.json'
import * as abdm_verify_otp_fail_invalid_request_id from '../../testdata/abdm_verify_otp_fail_invalid_request_id.json'



export default class ABDMEnrollmentForm extends Component {

    // Shared state across steps
    state = {
        enrollment: {
            step1 : {
                document_id: '',
                language: 'ENGLISH',
                consent: '',
            },
            step2: {
                req_id: '',
                otp: '',
                mobile: '',
            },
            message: '',
            error_message: '',
            consent_text: "I provide consent for sharing my aadhaar details and creation of ABHA Card.",
            errors: {},
        },
        enrollmentRequestOtpURL: '/dashboard/abdm/enroll/request_otp',
        enrollmentVerifyOtpURL: '/dashboard/abdm/enroll/verify_otp',
        step: 1,
        otp_resend_timer: 0,
        show_resend_button: false,
        otp_timer: undefined
    };

    // this check is any error is raised
    hasError() {
        for( const key in this.state.enrollment.errors) {
            let value = this.state.enrollment.errors[key];
            if(value.length > 0) {
                return true
            }
        }
        return false;
    }


    // STEP 1 : methods

    // this ensures that if input element is updated, the global state is also updated.
    handleStep1Change = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        if(this.state.step == 1 && name == "language") {
            this.updateContentText(value);
        }
        this.setState({
            enrollment:{
                ...this.state.enrollment,
                step1: {
                    ...this.state.enrollment.step1,
                    [name]: value
                }
            }
        });
    }

    // this is invoked when Crete Abha card button is clicked
    requestOTP = (e) => {
        e.preventDefault();
        this.validateStep1();
        this.forceUpdate();
        if(!this.hasError()) {
            this.setState({step:0});
            if(Mode.DEBUG) {
                let response = abdm_request_otp_success.default;
                this.handleRequestOTPResponse(response);
            } else {
                let aadhaarJson = JSON.stringify(this.state.enrollment.step1);
                fetch(this.state.enrollmentRequestOtpURL, get_request_option("POST", aadhaarJson))
                .then(response => response.json())
                .then(data => {
                    this.handleRequestOTPResponse(data);
                }).catch( err => {
                    console.log(err);
                });
            }
        }
    }

    validateStep1 = () => {
        this.state.enrollment.errors.document_id = this.state.enrollment.step1.document_id.length !== 12 ? 'Please enter your 12 digit aadhaar number' : '';
        this.state.enrollment.errors.language = this.state.enrollment.step1.language.length === 0 ? 'Please select a language' : '';
        this.state.enrollment.errors.consent = (this.state.enrollment.step1.consent.length === 0 || this.state.enrollment.step1.consent === false) ? 'Please provide your consent' : '';
    }

    handleRequestOTPResponse(response) {
        if(response.status === 200) {
            this.state.enrollment.step2.req_id = response.req_id;
            this.state.enrollment.message = response.message;
            this.setState({
                step: 2,
                enrollment:{
                    ...this.state.enrollment,
                    errors: {}
                }
            })
            this.startOTPTimer()
        } else if(response.status === 500) {
            if(response.message == "Invalid aadhaar card number") {
                this.state.enrollment.errors.document_id = response.message;
            } else {
                this.state.enrollment.error_message = "We encountered an issue while requesting OTP for ABAHA enrollment. Please try again later."
            }
            this.setState({
                step: 1
            })
        }
    }
    // this is called when consent language is changed
    updateContentText = (language) => {
        if(language == "ENGLISH") {
            this.state.enrollment.consent_text = "I provide consent for sharing my aadhaar details and creation of ABHA Card."
        } else if(language == "HINDI") {
            this.state.enrollment.consent_text = "मैं अपना आधार विवरण साझा करने और ABHA कार्ड बनाने के लिए सहमति प्रदान करता हूं।"
        } else if(language == "KANNADA") {
            this.state.enrollment.consent_text = "ನನ್ನ ಆಧಾರ್ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಮತ್ತು ABHA ಕಾರ್ಡ್ ರಚನೆಗೆ ನಾನು ಸಮ್ಮತಿಯನ್ನು ನೀಡುತ್ತೇನೆ."
        }
    }

    // STEP 1 method ends

    // STEP 2 method starts

    // this ensures that if input element is updated, the global state is also updated.
    handleStep2Change = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        this.setState({
            enrollment:{
                ...this.state.enrollment,
                step2: {
                    ...this.state.enrollment.step2,
                    [name]: value
                }
            }
        });
    }

    changeAadhaarNumber = () => {
        clearInterval(this.state.otp_timer);
        this.setState({
            enrollment:{
                ...this.state.enrollment,
                step1: {
                    document_id: '',
                    language: 'ENGLISH',
                    consent: '',
                },
                message: '',
                error_message: '',
                consent_text: "I provide consent for sharing my aadhaar details and creation of ABHA Card.",
                errors: {},
            },
            step: 1
        });
    }

    formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    startOTPTimer = () => {
        this.state.show_resend_button = false;
        this.state.otp_resend_timer = 120;
        this.state.otp_timer = setInterval(() => {
            this.setState({
                otp_resend_timer: this.state.otp_resend_timer - 1
            })
            if(this.state.otp_resend_timer == 0) {
                this.setState({
                    show_resend_button: true
                })
                clearInterval(this.state.otp_timer);
            }
        }, 1000)
    }

    validateStep2 = () => {
        this.state.enrollment.errors.otp = this.state.enrollment.step2.otp.length !== 6 ? 'Please enter 6 digit OTP' : '';
        this.state.enrollment.errors.mobile = this.state.enrollment.step2.mobile.length !== 10 ? 'Please enter 10 digit mobile number' : '';
    }

    handleOTPVerificationResponse = (response) => {
        if(response.status === 200) {
            // stop timer
            clearInterval(this.state.otp_timer);
            if(response.action == "verify_mobile") {
                // goto step 3 : (mobile verification) store any state if required
                this.setState({
                    step: 3
                });
            } else if( response.isNew ) {
                // goto step 4 : ( phr address selection )
                this.setState({
                    step: 4
                });
            } else {
                // goto step 5 : download abha card
                this.setState({
                    step: 5
                });
            }
        } else if(response.status === 500) {
            // go back to step 2 and show error message.
            this.setState({
                step: 2,
                enrollment:{
                    ...this.state.enrollment,
                    error_message: response.message
                }
            });
            // show error message
        }
    }

    verifyOTP = (e) => {
        e.preventDefault();
        // clear any error
        this.setState({
            enrollment:{
                ...this.state.enrollment,
                error_message: ''
            }
        });

        this.validateStep2();
        this.forceUpdate();
        if(!this.hasError()) {
            this.setState({step:0});
            if(Mode.DEBUG) {
                let response = abdm_verify_otp_fail_invalid_request_id.default;
                this.handleOTPVerificationResponse(response);
            } else {
                let otpData = JSON.stringify(this.state.enrollment.step2);
                fetch(this.state.enrollmentVerifyOtpURL, get_request_option("POST", otpData))
                .then(response => response.json())
                .then(data => {
                    this.handleOTPVerificationResponse(data);
                }).catch( err => {
                    console.log(err);
                });
            }
        }
    }

    // STEP 2 methods ENDS


    render() {
        if(!(this.props.clinic.abdm && this.props.clinic.abdm.enabled)) {
            return <Navigate to="/not-found" />;
        }
        return (
            <Container fluid>
                {(this.state.step === 1)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='page-header'>
                        <h1 className='page-title'>Create ABHA Card (Health ID)</h1>
                    </Col>
                    <Col lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block service-block-nohover">
                            <h3 className='mb-4'>Using Aadhaar Number</h3>
                            {(this.state.enrollment.error_message.length > 0)?
                            <div className='alert alert-danger'>
                                {this.state.enrollment.error_message}
                            </div>
                            :null}

                            <div className="form-group mb-5">
                                <label className="control-label sr-only required" htmlFor="aadhaar"></label>
                                <input id="document_id" name="document_id" type="number" placeholder="Enter your Aadhaar Number" className="form-control" onChange={this.handleStep1Change} />
                                <p className='error_msg'>{this.state.enrollment.errors.document_id}</p>
                            </div>

                            <div className="form-group mb-3">
                                <label className="control-label required" style={{textAlign:"left", width:"100%", marginBottom:"0px", fontSize:"10px", fontWeight:"bold"}} htmlFor="Language">Select language</label>
                                <select id="language" name="language" placeholder="Language" type="text" className="form-control" onChange={this.handleStep1Change} style={{marginTop:"0px"}}>
                                    <option value="ENGLISH">English</option>
                                    <option value="HINDI">Hindi</option>
                                    <option value="KANNADA">Kannada</option>
                                </select>
                                <p className='error_msg'>{this.state.enrollment.errors.language}</p>
                            </div>
                                
                            <div className="form-group mb-3">
                                <div style={{background:"#f9f9f9",border:"1px solid #ddd",textAlign:"left",fontSize:"12px","padding":"10px"}}>
                                    { this.state.enrollment.consent_text }
                                </div>
                            </div>

                            <div className="form-check">
                                <input className="form-check-input" type="checkbox" name="consent" onChange={this.handleStep1Change} style={{ marginTop: '7px'}} />
                                <p style={{ textAlign: 'left', paddingTop: '5px', fontSize: 'small', marginBottom: '15px', marginTop: '20px' }}>I agree to the terms and conditions. </p>
                                <p className='error_msg'>{this.state.enrollment.errors.consent}</p>
                            </div>

                            <button className="btn btn-primary btn-block w-100 mt-3" onClick={this.requestOTP}>Create ABHA Card</button>
    
                            <div className='mt-5'>
                            <p style={{fontSize:"12px"}}><strong>APPROVED BY NHA</strong> <img src='https://www.eka.care/_next/image?url=https%3A%2F%2Fcdn.eka.care%2Fvagus%2Fckzr7cdjn00150shl4pya2ris.png&w=96&q=75'/></p>
                            </div>
                        </div>
                    </Col>
                </Row>:null}
                {(this.state.step === 0)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="section-title">
                            <h1>Loading...</h1>
                        </div>
                    </Col>
                    <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                :null}
                {(this.state.step === 2)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='page-header'>
                        <h1 className='page-title'>Create ABHA Card (Health ID)</h1>
                    </Col>
                    <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block service-block-nohover">
                            <h3 className='mb-3'>Verify OTP</h3>
                            <div className='alert alert-success'>
                                {this.state.enrollment.message}
                            </div>
                            {(this.state.enrollment.error_message)?
                            <div className='alert alert-danger'>
                                {this.state.enrollment.error_message}
                            </div>:null}
                            <div className="form-group mt-3">
                                <label className="control-label sr-only required" htmlFor="otp"></label>
                                <input id="otp" name="otp" type="number" placeholder="Enter the 6 digit OTP" className="form-control" onChange={this.handleStep2Change} max={999999}/>
                                <p className='error_msg'>{this.state.enrollment.errors.otp}</p>
                            </div>
                            <div className='mt-3'>
                                {(this.state.show_resend_button)?
                                    <a href='#' className='btn btn-light'>Resend OTP</a>
                                :
                                    <p className='btn'>Resend in {this.formatTime(this.state.otp_resend_timer)}</p>
                                }
                                | <button onClick={this.changeAadhaarNumber} className='btn'>Change aadhaar Number</button>
                            </div>
                            <div className="form-group" style={{marginBottom:"20px"}}>
                                <label className="control-label required" style={{textAlign:"left", width:"100%", marginBottom:"0px", marginTop:"20px"}} htmlFor="mobile">This mobile number will be used for all the communications</label>
                                <input id="mobile" name="mobile" type="number" placeholder="Enter your mobile number" className="form-control" value={this.state.mobile} onChange={this.handleStep2Change} min={0} style={{marginTop:"0px"}}/>
                                <p className='error_msg'>{this.state.enrollment.errors.mobile}</p>
                            </div>

                            <button className="btn btn-primary btn-block" onClick={this.verifyOTP}>Submit OTP</button>
                        </div>
                    </Col>
                </Row>
                :null}
                {(this.state.step === 3)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='page-header'>
                        <h1 className='page-title'>Create ABHA Card (Health ID)</h1>
                    </Col>
                    <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block service-block-nohover">
                            <h3 className='mb-3'>Verify Mobile</h3>
                            <div className='alert alert-success'>
                                {this.state.enrollment.message}
                            </div>
                            <div className="form-group mt-3">
                                <label className="control-label sr-only required" htmlFor="otp"></label>
                                <input id="otp" name="otp" type="number" placeholder="Enter the 6 digit OTP" className="form-control" onChange={this.handleStep2Change} max={999999}/>
                                <p className='error_msg'>{this.state.enrollment.errors.otp}</p>
                            </div>
                            <div className='mt-3'>
                                {(this.state.show_resend_button)?
                                    <a href='#' className='btn btn-light'>Resend OTP</a>
                                :
                                    <p className='btn'>Resend in {this.formatTime(this.state.otp_resend_timer)}</p>
                                }
                                | <button onClick={this.changeAadhaarNumber} className='btn'>Change aadhaar Number</button>
                            </div>
                            <div className="form-group" style={{marginBottom:"20px"}}>
                                <label className="control-label required" style={{textAlign:"left", width:"100%", marginBottom:"0px", marginTop:"20px"}} htmlFor="mobile">This mobile number will be used for all the communications</label>
                                <input id="mobile" name="mobile" type="number" placeholder="Enter your mobile number" className="form-control" value={this.state.mobile} onChange={this.handleStep2Change} min={0} style={{marginTop:"0px"}}/>
                                <p className='error_msg'>{this.state.enrollment.errors.mobile}</p>
                            </div>

                            <button className="btn btn-primary btn-block" onClick={this.verifyOTP}>Submit OTP</button>
                        </div>
                    </Col>
                </Row>
                :null}
                {(this.state.step === 4)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='page-header'>
                        <h1 className='page-title'>Create ABHA Card (Health ID)</h1>
                    </Col>
                    <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block service-block-nohover">
                            <h3 className='mb-3'>Select ABHA address</h3>
                            <div className='alert alert-success'>
                                {this.state.enrollment.message}
                            </div>
                            <div className="form-group mt-3">
                                <label className="control-label sr-only required" htmlFor="otp"></label>
                                <input id="otp" name="otp" type="number" placeholder="Enter the 6 digit OTP" className="form-control" onChange={this.handleStep2Change} max={999999}/>
                                <p className='error_msg'>{this.state.enrollment.errors.otp}</p>
                            </div>
                            <div className='mt-3'>
                                {(this.state.show_resend_button)?
                                    <a href='#' className='btn btn-light'>Resend OTP</a>
                                :
                                    <p className='btn'>Resend in {this.formatTime(this.state.otp_resend_timer)}</p>
                                }
                                | <button onClick={this.changeAadhaarNumber} className='btn'>Change aadhaar Number</button>
                            </div>
                            <div className="form-group" style={{marginBottom:"20px"}}>
                                <label className="control-label required" style={{textAlign:"left", width:"100%", marginBottom:"0px", marginTop:"20px"}} htmlFor="mobile">This mobile number will be used for all the communications</label>
                                <input id="mobile" name="mobile" type="number" placeholder="Enter your mobile number" className="form-control" value={this.state.mobile} onChange={this.handleStep2Change} min={0} style={{marginTop:"0px"}}/>
                                <p className='error_msg'>{this.state.enrollment.errors.mobile}</p>
                            </div>

                            <button className="btn btn-primary btn-block" onClick={this.verifyOTP}>Submit OTP</button>
                        </div>
                    </Col>
                </Row>
                :null}
                {(this.state.step === 5)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='page-header'>
                        <h1 className='page-title'>Create ABHA Card (Health ID)</h1>
                    </Col>
                    <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block service-block-nohover">
                            <h3 className='mb-3'>Download ABHA card</h3>
                            <div className='alert alert-success'>
                                {this.state.enrollment.message}
                            </div>
                            <div className="form-group mt-3">
                                <label className="control-label sr-only required" htmlFor="otp"></label>
                                <input id="otp" name="otp" type="number" placeholder="Enter the 6 digit OTP" className="form-control" onChange={this.handleStep2Change} max={999999}/>
                                <p className='error_msg'>{this.state.enrollment.errors.otp}</p>
                            </div>
                            <div className='mt-3'>
                                {(this.state.show_resend_button)?
                                    <a href='#' className='btn btn-light'>Resend OTP</a>
                                :
                                    <p className='btn'>Resend in {this.formatTime(this.state.otp_resend_timer)}</p>
                                }
                                | <button onClick={this.changeAadhaarNumber} className='btn'>Change aadhaar Number</button>
                            </div>
                            <div className="form-group" style={{marginBottom:"20px"}}>
                                <label className="control-label required" style={{textAlign:"left", width:"100%", marginBottom:"0px", marginTop:"20px"}} htmlFor="mobile">This mobile number will be used for all the communications</label>
                                <input id="mobile" name="mobile" type="number" placeholder="Enter your mobile number" className="form-control" value={this.state.mobile} onChange={this.handleStep2Change} min={0} style={{marginTop:"0px"}}/>
                                <p className='error_msg'>{this.state.enrollment.errors.mobile}</p>
                            </div>

                            <button className="btn btn-primary btn-block" onClick={this.verifyOTP}>Submit OTP</button>
                        </div>
                    </Col>
                </Row>
                :null}
            </Container>
        );
    }
}
