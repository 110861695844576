import { useEffect } from 'react';
import TopBar from '../components/Topbar/Topbar';
import Header from '../components/Header/Header';
import ABDMEnrollmentForm from '../components/ABDM/EnrollmentForm';
import Footer from '../components/Footer/Footer';

import { dynamicTitle, setDescription } from '../components/Helper/helper';
import { pageName } from '../components/Helper/constants';


function ABDMEnrollment(props) {
  useEffect(()=>{
    dynamicTitle( props.clinic, `${pageName.ABHAENROLL} - ${props.clinic.name}`);
  },[props.clinic]);
    return (
      <div>
        <TopBar clinic={props.clinic}/>
        <Header clinic={props.clinic}/>
        <ABDMEnrollmentForm clinic={props.clinic}/>
        <Footer clinic={props.clinic}/>
      </div>
    );
  }
  
  export default ABDMEnrollment;