import { Component } from 'react';
import TagManager from 'react-gtm-module';

class GoogleTagManager extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.addScripts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trackingId !== this.props.trackingId) {
      this.addScripts();
    }
  }

  addScripts() {
    const { trackingId } = this.props;
    if (trackingId) {
      TagManager.initialize({
        "gtmId": trackingId
      });
    }
  }

  render() {
    return null;
  }
}

export default GoogleTagManager;
