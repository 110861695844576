import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// helper
import {getToday, get_request_option} from '../Helper/helper'
import {Mode} from '../Helper/constants';
import * as appointment_success from '../../testdata/appointment_success.json';
// required for testing
// import * as appointment_500 from '../../testdata/appointment_500.json';
// import * as appointment_error from '../../testdata/appointment_error.json';



export default class AppointmentForm extends Component {

    componentDidMount() {
    // Update the browser's URL
    window.history.replaceState(null, '', '/appointment');
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        this.setState({
            appointment:{
                ...this.state.appointment,
                data: {
                    ...this.state.appointment.data,
                    [name]: value
                }
            }
        }, function() {
            if(this.state.appointment.errors[name]) {
                this.validate();
                this.forceUpdate();
            }
        }.bind(this));
    }

    handleFocus = (e) => {
        const target = e.target;
        target.type = 'date';
        setTimeout( function() {target.click();}, 100 );
    }

    handleBlur = (e) => {
        const target = e.target;
        if(target.value.length == 0) {
            target.type = 'text';
        }
    }

    showMessage(error_code) {
        let response = {};
        if(error_code === 500) {
            response = {
                'heading': "Please try again.",
                'message': "Sorry! we failed to submit your appointment. Please try again.",
                'icon': "/img/fail.png",
            }
        } else if(error_code === 400) {
            response = {
                'heading': "Appointment request was not sent !",
                'message': "Please select a different date as the clinic is closed on selected date.",
                'icon': "/img/fail.png",
            }
        } else {
            response = {
                'heading': "Thanks for your time !",
                'message': "Your appointment has been successfully booked.",
                'icon': "/img/pass.png",
            }
            window.history.replaceState(null, '', "/appointment/submitted");
        }
        this.setState({
            response : response,
            step: 2
        })
    }

    handleAppointmentResponse(response) {
        if(response.status === 201) {
            this.showMessage(201);
        } else if(response.status === 500) {
            this.showMessage(500);
        } else if(response.status === 400) {
            this.showMessage(400);
        } else if(response.status === 403) {
            this.setState({
                appointment:{
                    ...this.state.appointment,
                    errors: response.errors
                }
            }, function() {
                this.setState({step:1});
                this.forceUpdate();
            }.bind(this))
        }
    }

    hasError() {
        for( const key in this.state.appointment.errors) {
            let value = this.state.appointment.errors[key];
            if(value.length > 0) {
                return true
            }
        }
        return false;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.validate();
        this.forceUpdate();
        if(!this.hasError()) {
            this.setState({step:0});
            if(Mode.DEBUG) {
                let response = appointment_success.default;
                this.handleAppointmentResponse(response);
            } else {
                let appointmentJson = JSON.stringify(this.state.appointment.data);
                fetch(this.state.appointmentPostUrl, get_request_option("POST", appointmentJson))
                .then(response => response.json())
                .then(data => {
                    this.handleAppointmentResponse(data);
                }).catch( err => {
                    this.showMessage(500);
                });
            }
        }
    }

    validate = () => {
        this.state.appointment.errors.name = (this.state.appointment.data.name.length === 0) ? 'Please enter your name' : '';
        this.state.appointment.errors.mobile = (this.state.appointment.data.mobile.length < 8) ? 'Please enter your valid contact number' : '';
        this.state.appointment.errors.date = (this.state.appointment.data.date.length === 0) ? 'Please enter select a valid date' : '';
        this.state.appointment.errors.time_option = (this.state.appointment.data.time_option.length === 0) ? 'Please enter select a valid suitable time' : '';
        this.state.appointment.errors.consent = (this.state.appointment.data.consent.length === 0 || this.state.appointment.data.consent === false) ? 'Please provide your consent' : '';
        if(this.state.appointment.data.date === new Date().toJSON().slice(0, 10)) {
            var currentHour = new Date().getHours();
            var slot_error = "Please select different time option.";
            // selected date is today;
            if(this.state.appointment.data.time_option == "M") {
                if(currentHour >= 12) {
                    this.state.appointment.errors.time_option = slot_error;
                }
            } else if(this.state.appointment.data.time_option == "A") {
                if(currentHour >= 18 ) {
                    this.state.appointment.errors.time_option = slot_error;
                }
            } else if(this.state.appointment.data.time_option == "E") {
                if(currentHour >= 22 ) {
                    this.state.appointment.errors.time_option = slot_error;
                }
            }
        }
    }

    state = {
        appointment: {
            data : {
                name: '',
                mobile: '',
                date: '',
                time_option: '',
                consent: '',
            },
            errors: {}
        },
        appointmentPostUrl: '/api/appointment',
        step: 1
    };

    render() {
        return (
            <Container fluid>
                {(this.state.step === 1)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='page-header'>
                        <h1 className='page-title'>Make an Appointment</h1>
                    </Col>
                    <Col lg={{ span: 8, offset: 2 }} md={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block service-block-nohover">
                            {this.props.clinic.overrides && this.props.clinic.overrides.appointment_notice?
                            <p className='holiday-notice' dangerouslySetInnerHTML={{__html:this.props.clinic.overrides.appointment_notice}}></p>:null}
                            {this.props.clinic.holiday.enabled?
                            <p className='holiday-notice'>
                                <b>Notice:</b> We are closed
                                {this.props.clinic.holiday.start===this.props.clinic.holiday.end?
                                <> on {this.props.clinic.holiday.start}. </>:<> from {this.props.clinic.holiday.start} to {this.props.clinic.holiday.end}. </>}
                                Please avoid booking appointments. Thank you.
                            </p>:null}
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <label className="control-label sr-only required" htmlFor="name"></label>
                                    <input id="name" name="name" type="text" placeholder="Name" className="form-control" onChange={this.handleChange} />
                                    <p className='error_msg'>{this.state.appointment.errors.name}</p>
                                </div>
                                <div className="form-group">
                                    <label className="control-label sr-only required" htmlFor="mobile"></label>
                                    <input id="mobile" name="mobile" type="number" placeholder="Mobile" className="form-control" value={this.state.mobile} onChange={this.handleChange} min={0}/>
                                    <p className='error_msg'>{this.state.appointment.errors.mobile}</p>
                                </div>
                                <div className="form-group">
                                    <label className="control-label sr-only required" htmlFor="select Date"></label>
                                    <input type="text" id="date" name="date" placeholder="Select Date" className="form-control" onChange={this.handleChange} onFocus={this.handleFocus}
    onBlur={this.handleBlur} min={getToday()}/>
                                    <p className='error_msg'>{this.state.appointment.errors.date}</p>
                                </div>
                                <div className="form-group">
                                    <label className="control-label sr-only required" htmlFor="time_option"> </label>
                                    <select id="time_option" name="time_option" placeholder="Time Option" type="text" className="form-control" onChange={this.handleChange}>
                                        <option value="">Your Suitable Time</option>
                                        <option value="M">Morning (Before 12:00 PM)</option>
                                        <option value="A">Afternoon (Between 12:00 PM to 6:00 PM)</option>
                                        <option value="E">Evening (After 6:00 PM)</option>
                                    </select>
                                    <p className='error_msg'>{this.state.appointment.errors.time_option}</p>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" name="consent" onChange={this.handleChange} style={{ marginTop: '7px'}} />
                                    <p style={{ textAlign: 'left', paddingTop: '5px', fontSize: 'small', marginBottom: '15px', marginTop: '20px' }}>I declare that the information provided is correct. I would like to receive communication about the offered services.</p>
                                    <p className='error_msg'>{this.state.appointment.errors.consent}</p>
                                </div>
                                <input type="submit" name="book" className="btn btn-primary btn-block" value="make an appointment"/>
                            </form>
                        </div>
                    </Col>
                </Row>:null}
                {(this.state.step === 0)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="section-title">
                            <h1>Loading...</h1>
                        </div>
                    </Col>
                    <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                :null}
                {(this.state.step === 2)?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="section-title">
                            <h1>{ this.state.response.heading }</h1>
                        </div>
                    </Col>
                    <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block">
                            <img src={ this.state.response.icon } style={{maxWidth:'20%', marginBottom:'20px'}} alt='status icon'/>
                            <p>{ this.state.response.message }</p>
                            <Link to="/">
                                <button name="home" className="btn btn-primary btn-block">Goto Home</button>
                            </Link>
                        </div>
                    </Col>
                </Row>
                :null}
            </Container>
        );
    }
}
