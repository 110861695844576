import { Component } from 'react';

class ShownPixel extends Component {
  constructor(props) {
    super(props);
    this.script1 = null;
  }

  componentDidMount() {
    this.addScripts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.trackingId !== this.props.trackingId) {
      this.removeScripts();
      this.addScripts();
    }
  }

  componentWillUnmount() {
    this.removeScripts();
  }

  addScripts() {
    const { trackingId } = this.props;
    if (trackingId) {
      this.script1 = document.createElement('script');
      this.script1.src = `https://shown.io/metrics/${trackingId}`;
      this.script1.defer = true;
      document.head.appendChild(this.script1);
    }
  }

  removeScripts() {
    if (this.script1) {
      document.head.removeChild(this.script1);
      this.script1 = null;
    }
  }

  render() {
    return null;
  }
}

export default ShownPixel;
